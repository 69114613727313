import React from 'react';
import PropTypes from 'prop-types';
import jsonp from 'jsonp';
import toQueryString from 'to-querystring';
import { push } from 'gatsby-link';
import * as routes from '../../constants/routes';

const getAjaxUrl = (url) => url.replace('/post?', '/post-json?');

class MailchimpSubscribe extends React.Component {
    state = {
        status: null,
        message: null
    };
    subscribe = (data) => {
        const params = toQueryString(data);
        const url = getAjaxUrl(this.props.url) + '&' + params;
        this.setState(
            {
                status: 'sending',
                message: null
            },
            () =>
                jsonp(
                    url,
                    {
                        param: 'c'
                    },
                    (err, data) => {
                        if (err) {
                            this.setState({
                                status: 'error',
                                // message: err
                                message: 'Please enter a valid email'
                            });
                        } else if (data.result !== 'success') {
                            this.setState({
                                status: 'error',
                                // This will overwrite the error message stating that somebody has already subscribed. This is fine for the short-term.
                                // message: data.msg
                                message: 'Please enter a valid email'

                            });
                        } else {
                            push(routes.BETASIGNEDUP);
                            /*
              this.setState({
                status: 'success',
                message: data.msg
              });
              */
                        }
                    }
                )
        );
    };
    render() {
        return this.props.render({
            subscribe: this.subscribe,
            status: this.state.status,
            message: this.state.message
        });
    }
}

MailchimpSubscribe.propTypes = {
    render: PropTypes.func,
    url: PropTypes.string.isRequired
};

export default MailchimpSubscribe;
