import React, { Component } from 'react';

import Layout from '../layouts/index';
import MailchimpSubscribe from '../components/MailChimp';
import helpthemimg from '../assets/helpthem.png';
import OneClickVideo from '../assets/one-click-video.png';
import Annotations from '../assets/annotations.png';
// import VirtualInstructions from '../assets/virtual-instructions.png';
import ShareScreen from '../assets/share-screen.png';
import Sales from '../assets/sales.png';
import CustomerSupport from '../assets/customer-support.png';
import './marketing.scss';

class Marketing extends Component {
  state = {
    email: ''
  };
  updateEmail = (ev) => {
    this.setState({
      email: ev.target.value
    });
  };
  handleOnKeyPress = (subscribe, ev) => {
    if (ev.key === 'Enter' || ev.charCode === 13) {
      this.handleSubscribe(subscribe);
    }
  };
  handleSubscribe = (subscribe) => {
    subscribe({ EMAIL: this.state.email });
  };
  renderSignup() {
    return (
      <MailchimpSubscribe
        url={
          'https://wrangle.us18.list-manage.com/subscribe/post?u=136fa6eec2fa621adbe461fbc&amp;id=b23a1c6f91'
        }
        render={({ subscribe, status, message }) => {
          let boundFn = this.handleOnKeyPress.bind(this, subscribe);
          return (
            <div>
              <div className="input-field">
                <input
                  onKeyPress={boundFn}
                  type="email"
                  placeholder="Email address"
                  className="input email validate"
                  onChange={this.updateEmail}
                />
              </div>
              <button
                className="button primary"
                onClick={this.handleSubscribe.bind(
                  this,
                  subscribe
                )}
              >
                Join our Waitlist
                            </button>
              {status === 'sending' && (
                <p style={{ color: 'white' }}>sending...</p>
              )}
              {status === 'error' && (
                <p className="mailchimp-error"
                  dangerouslySetInnerHTML={{
                    __html: message
                  }}
                />
              )}
              {status === 'success' && (
                <p style={{ color: 'green' }}>Subscribed !</p>
              )}
            </div>
          );
        }}
      />
    );
  }
  render() {
    return (
      <Layout location={this.props.location}>
        <div className="marketing-container">
          <div className="marketing">
            <div className="columns">
              <div className="column signup">
                <h1 className="marketing-header">
                  A better way to help customers when you aren’t in the room.
                </h1>
                <h2>
                  Stop wasting time telling customers what you could show them instead. Wrangle offers 1-click video calls and simple tools to collaborate with customers over video and interact in their environment from the comfort of your desk.
                </h2>
                <h3>
                  Join our Beta
                </h3>
                {this.renderSignup()}
              </div>
              <div className="column helpthem-image is-narrow">
                <img
                  alt="Help them where they are"
                  src={helpthemimg}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="se-slope">
          <div className="not-slope">
            <h6 className="feature-summary">1-click Video Calls</h6>
            <h2 className="feature-tagline">Connect with your customers on any&nbsp;device.</h2>
            <div className="columns">
              <div className="column">
                <img
                  src={OneClickVideo}
                  alt="Easily start a video call with one-click."
                />
              </div>
              {/* Experiement */}
              <div className="column feature-bullets">
                <div className="flexy-center">
                  <div className="inner-item">
                    <p>Invite customers to join calls from their browser with a single click. Customers don't need to install any software to get started.</p>
                    <p>Diagnose customer issues with dual video and screen sharing.</p>
                  </div>
                </div>
              </div>
            </div>
            <h6 className="feature-summary large-spacer">Visual Collaboration</h6>
            <h2 className="feature-tagline">Seamlessly guide customers to the right&nbsp;answer.</h2>
            <div className="columns">
              <div className="column feature-bullets">
                <div className="flexy-center">
                  <div className="inner-item">
                    <p>Get on the same page with tools to annotate on live video and shared screens.</p>
                    <p>Pause the screen or video to provide more precise direction to customers.</p>
                  </div>
                </div>
              </div>
              <div className="column">
                <img
                  src={Annotations}
                  alt="Annotate on shared screens and video."
                />
              </div>
            </div>
            <h6 className="feature-summary large-spacer">Shared Screens</h6>
            <h2 className="feature-tagline">Easily share web and knowledge base content with&nbsp;customers. </h2>
            <div className="columns">
              <div className="column">
                <img
                  src={ShareScreen}
                  alt="Share your screen"
                />
              </div>
              <div className="column feature-bullets">
                <div className="flexy-center">
                  <div className="inner-item">
                    <p>Sharing web content takes into account your customer’s device and aspect ratio, ensuring that what you share looks clear and great from their perspective.</p>
                    <p>Leverage the content and knowledge base material that you’ve already created to help your customers.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-background">
          <div className="marketing-usecases">
            <h2 className="green-header">Perfect for Customer Sales & Support</h2>
            <div className="columns">
              <div className="column">
                <h4>Sales</h4>
                <img
                  src={Sales}
                  alt="Personalize sales with video calls." />
                <p>Personalize sales and build better relationships by integrating live video collaboration into your sales workflow.</p>
              </div>
              <div className="column">
                <h4>Customer Support</h4>
                <img
                  src={CustomerSupport}
                  alt="Visually guide customers on video and screen shares." />
                <p>Improve customer satisfaction and efficiency by visually guiding customers to troubleshoot and resolve common issues.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="call-to-action-white">
          <div className="columns">
            <div className="column" />
            <div className="column beta-signup">
              <h3>Join our Beta</h3>
              {this.renderSignup()}
            </div>
            <div className="column" />
          </div>
        </div>
      </Layout>
    );
  }
}

export default Marketing;
